<template>
  <div class="register">
  	<div class="box-left">
  		<div class="logo"><img :src="logo" style=""/></div>
  		<div class="title">
  			WELCOME!<br/>
  		</div>
  		<div class="desc">
  			<!--You are now ready to begin<br/>your tenancy application.-->
  		</div>
  	</div>
  	<div class="form-box">
  		<div class="block-nav">
  			<a href="javascript:;" @click="$router.push({path: '/'});">Back to Home</a>
  			<a href="javascript:;" @click="$i18n.locale='zh_HK'">繁</a>
  			<a href="javascript:;" @click="$i18n.locale='zh_CN'">简</a>
  		</div>
  		<h3 class="form-title" style="margin:20% 0 65px;">Your Account</h3>
  		<div class="form">
  			
            <div class="form-group" style="margin-top:40px;">
                <button @click="$router.push({path: 'applying'});" class="btn btn-info btn-block" type="button">Start your Application</button>
            </div>
            <div class="form-group" >
                <button @click="$router.push({path: '/'});" class="btn btn-default btn-block" type="button">Back to InnoCell Homepage</button>
            </div>
  		</div>
  	</div>
  </div>
</template>
<script>
export default {
    name: 'Welcome',
    data() {
        return {
           logo:require('../assets/img/Logos@2.png'),
        }
    },
    mounted() {
        if(!this.$cookies.isKey('_TOKEN_') || this.$cookies.get('_TOKEN_') == null ){
            window.location.href = "/#/login"
        }
    }
}
</script>
<style>
  body{
    background: #F1F1F1;
  }
	#app{
		height:99.8%;
    background: #F1F1F1;
	}
	.main-box{
		height:100%;
		
	}
	.content{
			height:100%;
		}
@media screen and (max-width:1079px){
	#app{
		background: #F1F1F1;

	}
}
</style>